// Package Imports
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";

// CSS Imports
import "./globalSidebar.scss";

// Context Imports
import { GlobalContex } from "../../globalContex";

// Image Imports
import Lock from "../../static/images/icons/lock.svg";
import { ReactComponent as Collapse_img } from "../../static/images/icons/collapse.svg";
import { ReactComponent as Collapse1_img } from "../../static/images/icons/collapse1.svg";
import { useNavigate } from "react-router-dom";
import defaultImg from "../../static/images/icons/app_placeholder.png";
import PubMainImg from "../../static/images/homeicon.svg";

const GlobalSidebar = ({ globalMenu }) => {
  const {
    collapse,
    setCollapse,
    loginData,
    setLoginData,
    selectedApp,
    setSelectedApp,
    setModalOpen,
    login,
    setLogin,
    selectedShareToken,
    setSelectedShareToken,
    licenseCheck,
    authorDetail,
    publicationDetail,
    selectedPublication,
    allPublications,
    setCardOpen,
    MainMenu
  } = useContext(GlobalContex);

  const navigate = useNavigate();

  // const [isHover, setIsHover] = useState("")

  useEffect(() => {
    if (localStorage.getItem("selectedApp") && selectedApp === null) {
      setSelectedApp(JSON.parse(localStorage.getItem("selectedApp")));
    } else if (localStorage.getItem("selectedApp")) {
      localStorage.setItem("selectedApp", JSON.stringify(selectedApp));
    } else {
      localStorage.setItem("selectedApp", JSON.stringify(globalMenu[0]));
      setSelectedApp(globalMenu[0]);
    }

    // console.log(isHover, "isHover")
  }, [selectedApp]);

  // useEffect(() => {
  //   console.log("call9")
  //   axios
  //     .get(
  //       `https://comms.globalxchange.io/coin/investment/path/get?investmentType=EQT&banker_email=${localStorage.getItem(
  //         "bankerEmailNew"
  //       )}`
  //     )
  //     .then((res) => {
  //       if (res.data.status) {
  //         let coinsymbol
  //         if (selectedShareToken) {
  //           console.log("selected-token " + JSON.parse(localStorage.getItem("selectedShareCoin")).token_profile_data.coinSymbol)
  //           coinsymbol = selectedShareToken?.token_profile_data.coinSymbol
  //         } else {
  //           coinsymbol = res.data.paths[0].token_profile_data.coinSymbol
  //           localStorage.setItem("selectedShareCoin", JSON.stringify(res.data.paths[0]))
  //           console.log("selected-token " + JSON.parse(localStorage.getItem("selectedShareCoin")).token_profile_data.coinSymbol)
  //         }
  //       } else {
  //         localStorage.setItem("selectedShareCoin", "Nothingg")
  //       }
  //     })
  // }, [selectedShareToken])

  return (
    <>
      {!collapse ? (
        <div
          // className="sidebar"
          className={loginData ? "sidebar-loggedin" : "sidebar-loggedout"}
          style={{
            // minHeight: window.innerHeight,
            opacity: loginData ? 1 : 0,
            height: "100vh",
          }}
        >
          <div className="globalItem" onClick={(e) => {
            setSelectedApp(MainMenu[0]);
            navigate(`/${MainMenu[0].appName}`);
            setCardOpen(false);
          }} style={{
            cursor: "pointer",
            opacity: 1,
            border: selectedApp?.appName === MainMenu[0].appName ? "solid 0.5px #e7e7e7" : "",
          }}>
            <img src={MainMenu[0].appLogo} alt="publications" style={{ width: "35px" }} />
            <div className="hoveredData1">
              {MainMenu[0].DispName}
            </div>
          </div>
          <div
            style={{
              // minHeight: loginData
              //   ? window.innerHeight - 110
              //   : window.innerHeight,
              overflowY: "scroll",
              // display: "flex",
              // flexDirection: "column",
              // justifyContent: "center",
              textAlign: "center",
            }}
          >

            {globalMenu.map((item, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="globalItem"
                    // onMouseEnter={() => setIsHover(item.appName)}
                    // onMouseLeave={() => setIsHover("")}
                    style={{
                      opacity: selectedApp?.appName === item.appName ? 1 : 0.3,
                      border: selectedApp?.appName === item.appName ? "solid 0.5px #e7e7e7" : "",

                    }}
                    onClick={(e) => {
                      // if (loginData) {
                      if (
                        item.appName === "MyApps" ||
                        item.appName === "MyCryptoBrand" ||
                        item.appName === "CapitalMarkets" ||
                        item.appName === "Authors" ||
                        item.appName === "Publishers" ||
                        item.appName === "Classrooms" ||
                        item.appName === "Marketsverse" ||
                        item.appName === "Malls"
                      ) {
                        // if (selectedApp?.appName === item.appName) {
                        // if (authorDetail !== null && authorDetail !== undefined && allPublications !== null && allPublications !== undefined && allPublications.length > 0) {
                        setSelectedApp(item);
                        navigate(`/${item.appName}`);
                        setCardOpen(false);
                        // }
                        // }
                      }
                      // } else {
                      //   if (
                      //     item.appName === "MyCryptoBrand" ||
                      //     item.appName === "Banker" ||
                      //     item.appName === "Authors" ||
                      //     item.appName === "Publishers"
                      //   ) {
                      //     if (selectedApp?.appName === item.appName) {
                      //       setSelectedApp(item);
                      //       navigate(`/${item.appName}`);
                      //     }
                      //   }
                      // }
                    }
                    }
                  >
                    <img src={item?.appLogo} className="globalImg" alt="" style={{ width: selectedApp?.appName === item.appName ? "30px" : "25px" }} />
                    <div className="hoveredData1"
                    >
                      {item.DispName}
                    </div>

                  </div>
                </>
              );
            })}
          </div>

          {loginData ? (
            <div className="lock">
              <div className="bottomIcon">
                <div
                  style={{
                    height: "70px",
                    // background: "red",
                    // borderTop: "solid 1px #e7e7e7",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 30px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div className="shadowHover">
                      {loginData?.user.profile_img ? (
                        <img
                          onClick={(e) => setModalOpen(true)}
                          src={loginData?.user.profile_img}
                          alt=""
                          width="37px"
                          height="37px"
                          style={{ borderRadius: "50%", cursor: "pointer" }}
                        />
                      ) : (
                        <img
                          onClick={(e) => setModalOpen(true)}
                          src={defaultImg}
                          alt=""
                          width="37px"
                          style={{ borderRadius: "50%", cursor: "pointer" }}
                        />
                      )}
                    </div>

                    {/* <div style={{ paddingLeft: "9px" }}>
                      <div className="name">{loginData?.user.name}</div>
                      <div className="email">{loginData?.user.email}</div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div
                className="bottomIcon"
                onClick={(e) => {
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                <img src={Lock} alt="" />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {/* {loginData ? (
        <div className="collapseButton" onClick={(e) => setCollapse(!collapse)}>
          {collapse ? (
            <Collapse1_img fill={selectedApp?.appColor} stroke="none" />
          ) : (
            <Collapse_img fill={selectedApp?.appColor} stroke="none" />
          )}
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default GlobalSidebar;
