import { createContext, useState, useEffect, useRef } from "react";

import Meta from "./static/images/icons/meta.svg";
import mcbLogo from "./static/images/icons/mcbLogo.svg";
import Banker from "./static/images/menulogos/Banker.svg";
import Capitalized from "./static/images/menulogos/Capitalized.svg";
import Create from "./static/images/menulogos/Create.svg";
import FundManager from "./static/images/menulogos/FundManager.svg";
import OTCDesks from "./static/images/menulogos/OtcDesks.svg";
import Terminals from "./static/images/menulogos/Terminals.svg";

import meta_full from "./static/images/logos/mverse_full.svg";
import mcbLogo_full from "./static/images/logos/mcb_full.svg";
import banker_full from "./static/images/logos/banker_full.svg";
import cap_full from "./static/images/logos/cap_full.svg";
import create_full from "./static/images/logos/create_full.svg";
import otc_full from "./static/images/logos/otc_full.svg";
import terminals_full from "./static/images/logos/terminals_full.svg";
import funds_full from "./static/images/logos/funds_full.svg";

import crm from "./static/images/sidebarIcons/crm.svg";
import dash from "./static/images/sidebarIcons/dash.svg";
import affiliates from "./static/images/sidebarIcons/affiliates.svg";
import vaults from "./static/images/sidebarIcons/vaults.svg";
import terminal from "./static/images/sidebarIcons/terminal.svg";
import bonds from "./static/images/sidebarIcons/bonds.svg";
import loans from "./static/images/sidebarIcons/socially.svg";
import Lock from "./static/images/icons/lock.svg";
import defaultImg from "./static/images/icons/app_placeholder.png";
import profile from "./static/images/sidebarIcons/profile.svg";
import content from "./static/images/sidebarIcons/content.svg";
import publication from "./static/images/sidebarIcons/publication.svg";
import rewardsIcon from "./static/images/sidebarIcons/rewards.svg";
import AdminContentIcon from "./static/images/sidebarIcons/AdminContentIco.svg";

import revenue from "./static/images/sidebarIcons/revenue.svg";
import assets from "./static/images/sidebarIcons/assets.svg";
import tokenswapico from "./static/images/sidebarIcons/tokenswapico.svg";
import externalwithdraw from "./static/images/sidebarIcons/externalicon.svg";
import GXTicon from "./static/images/sidebarIcons/GXTicon.svg";
import TokenHashIco from "./static/images/sidebarIcons/TokenHashIco.svg";

import hyfi from "./static/images/templateLogos/hyfi.svg";
import wealth from "./static/images/templateLogos/wealth.svg";
import otcDesks from "./static/images/templateLogos/otcDesks.svg";
import terminals from "./static/images/templateLogos/terminals.svg";
import fundManagement from "./static/images/templateLogos/fundManagement.svg";
import nftMarketplace from "./static/images/templateLogos/nftMarketplace.svg";
import nftReward from "./static/images/templateLogos/nftReward.svg";
import defi from "./static/images/templateLogos/defi.svg";
import signals from "./static/images/templateLogos/signals.svg";
import marketsverseIcon from "./static/images/homeicon.svg";


import vaults_full from "./static/images/templateLogos/vaults_full.svg";
import tokenSwap_full from "./static/images/templateLogos/tokenSwap_full.svg";
import moneyMarkets_full from "./static/images/templateLogos/moneyMarkets_full.svg";
import affiliate_full from "./static/images/templateLogos/affiliate_full.svg";
import bondIssuance_full from "./static/images/templateLogos/bondIssuance_full.svg";

import bondMarkets_full from "./static/images/templateLogos/bondMarkets_full.svg";
import portfolioAi_full from "./static/images/templateLogos/portfolioAi_full.svg";
import shareTokenIssuance_full from "./static/images/templateLogos/shareTokenIssuance_full.svg";
import shareTokenMarket_full from "./static/images/templateLogos/shareTokenMarket_full.svg";
import fundsCoinIssuance_full from "./static/images/templateLogos/fundsCoinIssuance_full.svg";
import fundCoinMarketplace_full from "./static/images/templateLogos/fundCoinMarketplace_full.svg";
import indexFundsIssuance_full from "./static/images/templateLogos/indexFundsIssuance_full.svg";
import indexFundsMarketplace_full from "./static/images/templateLogos/indexFundsMarketplace_full.svg";

import { ReactComponent as Collapse_img } from "./static/images/icons/collapse.svg";
import { ReactComponent as Collapse1_img } from "./static/images/icons/collapse1.svg";
import pubAdminIcon from "./static/images/icons/pubAdminIcon.svg";
import AuthorMainImg from "./static/images/icons/AuthorMainImg.svg";
import AdvertiseMainImg from "./static/images/icons/AdvertiseMainImg.svg";
import web3icofull from "./static/images/icons/webfull.svg";
import publicationsFull from "./static/images/icons/PublicationsFull.svg";
import roomIco from "./static/images/icons/classroomIcon.svg"
import metaverseIcon from "./static/images/globaldrawer/metaversIcon.svg";
import CapitalMarketsIco from "./static/images/icons/capitalMarketsIco.svg";
import FirmsIco from "./static/images/icons/FirmsIco.svg";
import MallsIco from "./static/images/icons/MallsIco.svg";
import ExperiencesIco from "./static/images/icons/ExperiencesIco.svg";

import axios from "axios";

export const GlobalContex = createContext();

export const GlobalContexProvider = ({ children }) => {
  const [selectedApp, setSelectedApp] = useState(null);
  const [loginData, setLoginData] = useState(null);

  const [loading, setLoading] = useState(false);

  const [collapse, setCollapse] = useState(false);

  const [active, setActive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);

  const [bankerEmail, setBankerEmail] = useState("");
  const [step, setStep] = useState("");
  const [mainMenu, setMainMenu] = useState("");
  const [buy, setBuy] = useState('step1');

  const [globalMenuAdd, setGlobalMenuAdd] = useState(false);
  const [selectedFilterRequest, setSelectedFilterRequest] = useState("pending")

  const [filters, setFilters] = useState([]);
  const [refetchCourses, setRefetchCourses] = useState(false);

  const [selectedFilter1, setSelectedFilter1] = useState(null);
  const [selectedFilter2, setSelectedFilter2] = useState("");
  const [selectedFilter21, setSelectedFilter21] = useState("");
  const [filter1, setFilter1] = useState(false);
  const [filter2, setFilter2] = useState(false);
  const [customerEmailFilter, setCustomerEmailFilter] = useState(null);
  const [allCoins, setAllCoins] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [openCoinFilter, setOpenCoinFilter] = useState(false);
  const [refetchApi, setRefetchApi] = useState(false);
  const [tabSelected, setTabSelected] = useState("Crypto");
  const [globalSearch, setGlobalSearch] = useState("");
  const [query, setQuery] = useState("");
  const [shareToken, setShareToken] = useState(null);

  const [allShareToken, setAllShareToken] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [showCourseSubDraw, setShowCourseSubDraw] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [globalFilter, setGlobalFilter] = useState(false);

  const [showSubDraw, setShowSubDraw] = useState(false);
  const [bondSelected, setBondSelected] = useState(null);
  const [contractsData, setContractsData] = useState("");
  const [slider, setSlider] = useState(false);

  //Bond Filters

  const [selectedAssetFilters, setSelectedAssetFilters] = useState([]);
  const [selectedStatusFilters, setSelectedStatusFilters] = useState([
    "active",
  ]);
  const [selectedLengthFilter, setSelectedLengthFilter] = useState("");
  const [refetchBonds, setRefetchBonds] = useState(false);

  const [refetchTiers, setRefetchTiers] = useState(false);
  const [cardOpen, setCardOpen] = useState(false);

  //Terminal Admin Panel
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [selectedShareToken, setSelectedShareToken] = useState(
    localStorage.getItem("selectedShareCoin") || null
  );
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [selectedAppData, setSelectedAppData] = useState("");
  const [reloadpage, setreloadpage] = useState(false);
  const [holderData, setHolderData] = useState("");
  const [theApp, setTheApp] = useState("");
  const [compData, setCompData] = useState("");
  const [DistribData, setDistribData] = useState("");

  const [DistributionData, setDistributionData] = useState([]);
  const [getBankerTag, setgetBankerTag] = useState([]);
  const [compLoader, setCompLoader] = useState(true);
  const [distLoader, setDistLoader] = useState(true);
  const [selectedSubs, setSelectedSubs] = useState("");
  const [licenseCheck, setLicenseCheck] = useState("");
  const [coinsCombinations, setCoinsCombinations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fetchProductData, setFetchProductData] = useState(false)

  /// MCB States

  const [login, setLogin] = useState(false);
  const [banker, setBanker] = useState(null);
  const [bankerTag, setBankerTag] = useState("");
  const [allBankers, setAllBankers] = useState([]);

  const [showDraw, setShowDraw] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedSplashCoin, setSelectedSplashCoin] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTemplateMenu, setSelectedTemplateMenu] = useState(null);

  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedBrandApp, setSelectedBrandApp] = useState();
  const [allBrands, setAllBrands] = useState([]);
  const [allApps, setAllApps] = useState([]);
  const [mcbAdminLoading, setMcbAdminLoading] = useState(false);

  const [selectedMcbDashboardApp, setSelectedMcbDashboardApp] = useState(null);

  const [refetchAppData, setRefetchAppData] = useState(false);

  const [allAppsForBrand, setAllAppsForBrand] = useState([]);

  const [refetchBrands, setRefetchBrands] = useState(false);
  const [refetchApps, setRefetchApps] = useState(false);

  const [selectedMcbDashboardBrand, setSelectedMcbDashboardBrand] =
    useState(null);
  const [refetchBrandData, setRefetchBrandData] = useState(false);

  const [selectedMcbAssetsCrypto, setSelectedMcbAssetsCrypto] = useState(null);
  const [selectedMcbAssetsForex, setSelectedMcbAssetsForex] = useState(null);

  const [refetchFieldGroupData, setRefetchFieldGroupData] = useState(false);

  const [selectedFieldGroup, setSelectedFieldGroup] = useState(false);
  const [allPublications, setAllPublications] = useState([]);
  const [selectedPublication, setSelectedPublication] = useState(null);

  const [isMobile, setIsMobile] = useState(false);

  const [theCurrency, setTheCurrency] = useState("");
  const [refreshCall, setRefreshCall] = useState(false);
  const [coinIIRD, setCoinIIRD] = useState("");
  // const [tabSelected, setTabSelected] = useState("Crypto");
  const [theAsset, setTheAsset] = useState([]);
  const [crmUser, setCrmUser] = useState("");
  const [crmData, setCrmData] = useState([]);
  const [actionsSubDrawer, setActionsSubDrawer] = useState(false);
  const [videoActionsSubDrawer, setVideoActionsSubDrawer] = useState(false);
  const [StorySubDrawer, setStorySubDrawer] = useState(false);
  const [profileSubDrawer, setProfileSubDrawer] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [affiliateDrawer, setAffiliateDrawer] = useState(false);
  // const [contentTabSelected, setContentTabSelected] = useState("");
  const [refetchData, setRefetchData] = useState(false);
  const [refechProfile, setRefechProfile] = useState(false)
  const [refetchProduct, setRefechProduct] = useState(false)
  const [coinList, setCoinList] = useState([]);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [retailerTab, setRetailerTab] = useState('Products');
  const [product, setProduct] = useState([]);
  const [mallCoindata, setMallCoinData] = useState([]);
  const [filteredcoins, setfilteredcoins] = useState([]);
  const [productSubDrawer, setProductSubDrawer] = useState(false);
  const [popularAction, setPopularAction] = useState(false);
  const [planBAccountPicUploading, setplanBAccountPicUploading] =
    useState(false);
  const [selectedDropDown, setSelectedDropDown] = useState(false);

  const [coinSelect, setCoinSelect] = useState({
    coinImage:
      "https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png",
    coinName: "US Dollar",
    coinSymbol: "USD",
    symbol: "$",
    price: { USD: 1 },
  });
  const [coinLoading, setCoinLoading] = useState(false);
  const [coinListObject, setCoinListObject] = useState();
  useEffect(() => {
    axios

      .get("https://comms.globalxchange.io/coin/vault/get/all/coins")
      .then(({ data }) => {
        if (data.status) {
          let obj = {};
          data.coins.forEach((coin) => {
            obj[coin.coinSymbol] = coin;
          });
          setCoinListObject(obj);
        }
      });
  }, []);

  useEffect(() => {
    if (tabSelected !== "Requests") {
      setFilterDrawer(false)
    }
  }, [tabSelected])
  useEffect(() => {
    setCoinLoading(true);
    axios
      .post("https://comms.globalxchange.io/coin/vault/service/coins/get", {
        app_code: "ice",
      })
      .then((res) => {
        const { data } = res;
        if (data.status) {
          const { coins_data } = data;
          setCoinList(coins_data);
        }
      })
      .finally(() => setCoinLoading(false));
  }, []);
  // useEffect(() => {
  //   localStorage.setItem("userType", userType);
  // }, [userType]);
  const [searchQuery, setSearchQuery] = useState("");
  const [appList, setAppList] = useState([]);
  const [authorDetail, setAuthorDetail] = useState(null);
  const [appLoading, setAppLoading] = useState(false);
  const [appListFinal, setAppListFinal] = useState([]);
  const [refetchArticles, setRefetchArticles] = useState(false);

  useEffect(() => {
    setAppLoading(true);
    axios
      .get("https://comms.globalxchange.io/gxb/apps/get")
      .then((res) => {
        const { data } = res;
        if (data.status) {
          const { apps } = data;
          setAppList(apps);
        }
      })
      .finally(() => setAppLoading(false));
  }, []);

  // Add an event listener for scroll on component mount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      // Remove the event listener on component unmount
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    console.log("call")
  }




  const [selectedCoinSplash, setSelectedCoinSplash] = useState({
    coinName: "US Dollar",
    coinSymbol: "USD",
    symbol: "$",
    coinImage:
      "https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png",
    type: "fiat",
  });

  const templateList = [
    {
      logo: hyfi,
      name: "HyFi",
      desc: "The HyFi (Hybrid Finance) template enables your users to grow their digital asset portfolio through fixed income instruments. Both in the form of a variable daily interest rate and through fixed term bonds.",
      cards: [
        { logo: vaults_full },
        { logo: tokenSwap_full },
        { logo: moneyMarkets_full },
        { logo: affiliate_full },
        { logo: bondIssuance_full },
      ],
    },
    {
      logo: wealth,
      name: "Wealth",
      desc: "The Wealth Management template offers a total portfolio management system to your users which allows them to track their digital net-worth while giving them access to fixed income, equity, and crypto markets.",
      cards: [
        { logo: vaults_full },
        { logo: tokenSwap_full },
        { logo: moneyMarkets_full },
        { logo: affiliate_full },
        { logo: bondIssuance_full },
        { logo: bondMarkets_full },
        { logo: portfolioAi_full },
        { logo: shareTokenIssuance_full },
        { logo: shareTokenMarket_full },
        { logo: fundsCoinIssuance_full },
        { logo: fundCoinMarketplace_full },
        { logo: indexFundsIssuance_full },
        { logo: indexFundsMarketplace_full },
      ],
    },
    {
      logo: otcDesks,
      name: "OTCDesks",
      desc: "The OTCDesk is a standard fiat to cryptocurrency exchange application which empowers your users to fund their accounts with their local currency and swap into digital assets.",
      cards: [],
    },
    {
      logo: terminals,
      name: "Terminals",
      desc: "Terminals is an advanced cryptocurrency exchange platform which allows your users to high frequency trading across hundreds of digital assets. ",
      cards: [],
    },
    {
      logo: fundManagement,
      name: "Fund Management",
      desc: "The Fund Management template is an retail investment platform which allows users to grow their assets by investing and interacting with your specific FundCoin.",
      cards: [],
    },
    {
      logo: nftMarketplace,
      name: "NFT Marketplace",
      desc: "The NFT Marketplace allows enables NFT creators to list their NFT's on your platform as well as providing buyers with a secondary resale market.",
      cards: [],
    },
    {
      logo: nftReward,
      name: "NFTReward",
      desc: "The NFTReward template allows you to create an exclusive application for your NFT whereby users can earn rewards for holding your NFT.",
      cards: [],
    },
    {
      logo: defi,
      name: "Defi Markets",
      desc: "DefiMarkets is a decentralized routing engine that allows your users to manage their DEFI lending operations accross different protocols.",
      cards: [],
    },
    {
      logo: signals,
      name: "Signals",
      desc: "The Signals template allows you to launch your very own financial education platform where you can offer courses, analysis and market signals.",
      cards: [],
    },
  ];


  const MainMenu = [
    {
      appName: "Marketsverse",
      appLogo: marketsverseIcon,
      appFullLogo: marketsverseIcon,
      appTextColor: "#212529",
      appColor: "#186AB4",
      appData: "Marketsverse",
      DispName: "Marketsverse"
    },
  ]

  const globalMenu = [
    // {
    //   appName: "MarketsVerse",
    //   appLogo: Meta,
    //   appFullLogo: meta_full,
    //   appColor: "#142955",
    // },

    {
      appName: "MyApps",
      appLogo: metaverseIcon,
      appFullLogo: metaverseIcon,
      appTextColor: "#212529",
      appColor: "#186AB4",
      appData: "My Apps",
      DispName: "My Apps"
    },
    {
      appName: "MyCryptoBrand",
      appLogo: mcbLogo,
      appFullLogo: mcbLogo_full,
      appColor: "linear-gradient(275.12deg, #277EFB 7.36%, #B580F2 140.3%)",
      appData: "MyCryptoBrand",
      DispName: "MyCryptoBrand"
    },
    {
      appName: "CapitalMarkets",
      appLogo: CapitalMarketsIco,
      appFullLogo: CapitalMarketsIco,
      appTextColor: "#212529",
      appColor: "#186AB4",
      appData: "CapitalMarkets",
      DispName: "Capital Markets"
    },
    // {
    //   appName: "Banker",
    //   appLogo: Banker,
    //   appFullLogo: banker_full,
    //   appColor: "#18191D",
    //   appData: "Banker",
    //   DispName: "Banker"
    // },
    {
      appName: "Publishers",
      appLogo: pubAdminIcon,
      appFullLogo: publicationsFull,
      appColor: "#4B9DDC",
      appTextColor: "#212529",
      appData: "Don’t Have A/Publications/Account?",
      DispName: "Publishers"
    },
    {
      appName: "Authors",
      appLogo: AuthorMainImg,
      appFullLogo: publicationsFull,
      appColor: "#4B9DDC",
      appTextColor: "#212529",
      appData: " Create Your Own/AI Powered Blog/Today",
      DispName: "For Creators"
    },
    {
      appName: "Malls",
      appLogo: MallsIco,
      appFullLogo: MallsIco,
      appColor: "#4B9DDC",
      appTextColor: "#212529",
      appData: "Malls",
      DispName: "Malls"
    },
    {
      appName: "Classrooms",
      appLogo: roomIco,
      appFullLogo: roomIco,
      appTextColor: "#212529",
      appColor: "#186AB4",
      appData: "ClassRooms",
      DispName: "Classrooms"
    },
    {
      appName: "Firms",
      appLogo: FirmsIco,
      appFullLogo: FirmsIco,
      appColor: "#4B9DDC",
      appTextColor: "#212529",
      appData: "Firms",
      DispName: "Firms"
    },
    {
      appName: "Experiences",
      appLogo: ExperiencesIco,
      appFullLogo: ExperiencesIco,
      appColor: "#4B9DDC",
      appTextColor: "#212529",
      appData: "Experiences",
      DispName: "Experiences"
    },
    // {
    //   appName: "Advertise",
    //   appLogo: AdvertiseMainImg,
    //   appFullLogo: web3icofull,
    //   appTextColor: "#212529",
    //   appColor: "#4B9DDC;",
    //   appData: " Advertise Your/Business On/Publishers",
    // },

    // {
    //   appName: "Capitalized",
    //   appLogo: Capitalized,
    //   appFullLogo: cap_full,
    //   appColor: "#78C92E",
    // },
    // {
    //   appName: "Creating",
    //   appLogo: Create,
    //   appFullLogo: create_full,
    //   appColor: "#040606",
    // },
    // {
    //   appName: "FundManagers",
    //   appLogo: FundManager,
    //   appFullLogo: funds_full,
    //   appColor: "#186AB3",
    // },
    // {
    //   appName: "OTCDesks",
    //   appLogo: OTCDesks,
    //   appFullLogo: otc_full,
    //   appColor: "#1F4271",
    // },
    // {
    //   appName: "Terminals",
    //   appLogo: Terminals,
    //   appFullLogo: terminals_full,
    //   appColor: "#292929",
    // },
  ];

  const localMenu = [
    {
      menuName: "Dashboard",
      menuIcon: dash,
      enabled: true,
    },
    {
      menuName: "Bonds",
      menuIcon: bonds,
      enabled: true,
    },
    {
      menuName: "Loans",
      menuIcon: loans,
      enabled: false,
    },
    {
      menuName: "CRM",
      menuIcon: crm,
      enabled: true,
    },
    {
      menuName: "Affilaites",
      menuIcon: affiliates,
      enabled: false,
    },
    {
      menuName: "Terminal",
      menuIcon: terminal,
      enabled: false,
    },
  ];

  const bankerMenu = [
    {
      menuName: "Dashboard",
      menuIcon: dash,
      enabled: true,
    },
    {
      menuName: "Bonds",
      menuIcon: bonds,
      enabled: true,
    },
    {
      menuName: "CRM",
      menuIcon: crm,
      enabled: true,
    },
    // {
    //   menuName: "Loans",
    //   menuIcon: loans,
    //   enabled: false,
    // },

    // {
    //   menuName: "Affilaites",
    //   menuIcon: affiliates,
    //   enabled: false,
    // },
    // {
    //   menuName: "Terminal",
    //   menuIcon: terminal,
    //   enabled: false,
    // },
    {
      menuName: "DefiSuggestions",
      menuIcon: bonds,
      enabled: true,
    },
    {
      menuName: "DefiCoins",
      menuIcon: bonds,
      enabled: true,
    },
    {
      menuName: "Dividends",
      menuIcon: bonds,
      enabled: true
    },
    {
      menuName: "ShareTokens CRM",
      menuIcon: bonds,
      enabled: true
    },
    {
      menuName: "Affiliate",
      menuIcon: bonds,
      enabled: true
    },
    {
      menuName: "OTC Dashboard",
      menuIcon: bonds,
      enabled: true
    },
    {
      menuName: "OTC Payments",
      menuIcon: bonds,
      enabled: true
    },
    {
      menuName: "Terminal Assets",
      menuIcon: bonds,
      enabled: true
    }
  ];

  const mcbMenu = [
    // {
    //   menuName: "Profile",
    //   menuIcon: dash,
    //   enabled: true,
    // },
    // {
    //   menuName: "Dashboard",
    //   menuIcon: dash,
    //   enabled: true,
    // },
    // {
    //   menuName: "CRM",
    //   menuIcon: crm,
    //   enabled: true,
    // },
    // {
    //   menuName: "Revenue",
    //   menuIcon: revenue,
    //   enabled: true,
    // },
    {
      menuName: "Assets",
      menuIcon: assets,
      enabled: true,
    },
    {
      menuName: "Exchange",
      menuIcon: tokenswapico,
      enabled: true,
    },
    {
      menuName: `Withdrawals`,
      menuIcon: externalwithdraw,
      enabled: true,
    },
    // {
    //   menuName: `Subscriptions`,
    //   menuIcon: GXTicon,
    //   enabled: true,
    // },
    {
      menuName: `Explorer`,
      menuIcon: TokenHashIco,
      enabled: true,
    },
  ];

  const web3Menu = [
    {
      menuName: "Management",
      menuIcon: publication,
      enabled: true,
    },
    {
      menuName: "Rewards",
      menuIcon: rewardsIcon,
      enabled: true,
    },
    {
      menuName: "Content",
      menuIcon: AdminContentIcon,
      enabled: true,
    },
    {
      menuName: "Hire",
      menuIcon: revenue,
      enabled: true,
    }
  ];

  const authorsMenu = [
    {
      menuName: "My Profile",
      menuIcon: profile,
      enabled: true,
    },
    {
      menuName: "My Content",
      menuIcon: content,
      enabled: true,
    },
  ];

  const mallsMenu = [
    {
      menuName: "Stores",
      menuIcon: profile,
      enabled: true,
    },
    {
      menuName: "CRM",
      menuIcon: content,
      enabled: true,
    },
  ];

  const ClassMenu = [
    {
      menuName: "Content",
      menuIcon: content,
      enabled: true
    },
    {
      menuName: "CRM",
      menuIcon: crm,
      enabled: true
    }
  ]


  const appsMenu = [
    {
      menuName: "Profile",
      menuIcon: dash,
      enabled: true,
    },
    // {
    //   menuName: "Dashboard",
    //   menuIcon: dash,
    //   enabled: true,
    // },
    {
      menuName: "CRM",
      menuIcon: crm,
      enabled: true,
    },
    {
      menuName: `Subscriptions`,
      menuIcon: GXTicon,
      enabled: true,
    },
    {
      menuName: "Revenue",
      menuIcon: revenue,
      enabled: true,
    }
  ];



  const [userType, setUserType] = useState(
    localStorage.getItem("userType") || "App Owner"
  );
  useEffect(() => {
    localStorage.setItem("userType", userType);
  }, [userType]);

  const FormatNumber = (value, prec) => {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: prec,
      minimumFractionDigits: prec,
    }).format(isNaN(value) ? 0 : value);
  };

  const FormatCurrency = (value = 0, coin = "USD") => {
    if (coin.toUpperCase() === "BTC" || coin.toUpperCase() === "ETH") {
      if (value < 10) {
        return FormatNumber(value, 4);
      } else {
        return FormatNumber(value, 3);
      }
    }
    return FormatNumber(value, 2);
  };

  const getOrdinalSuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return 'th';
    }

    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  const getDisplayDate = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const suffix = getOrdinalSuffix(day);
    const formattedDate = dateObj.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    return formattedDate.replace(/\d+/, `${day}${suffix}`);
  }

  const getDisplayTime = (date) => {
    const dateObj = new Date(date);
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    //add 5 hours and 30 minutes to get to IST
    let istHours = parseInt(formattedHours) + 5;
    let istMinutes = parseInt(formattedMinutes) + 30;
    if (istMinutes >= 60) {
      istHours += 1;
      istMinutes -= 60;
    }
    const ampm = parseInt(istHours) >= 12 ? 'PM' : 'AM';
    const finalHours = istHours % 12 === 0 ? 12 : istHours % 12;
    const finalMinutes = istMinutes < 10 ? `0${istMinutes}` : istMinutes;
    return `${finalHours}:${finalMinutes} ${ampm} IST`;
  }

  const getDays = (date) => {
    const dateObj = new Date(date);
    //subtract today's date from the target date
    const diff = new Date().getTime() - dateObj.getTime();
    //convert from milliseconds to days and remaining hours
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //return the days and hours
    if (days === 0) {
      return hours + " Hours"
    } else if (days === 1) {
      return days + " Day"
    } else {
      return days + " Days"
    }

  }

  const formatDate = (inputDate) => {
    // Split the input date string into year, month, and day
    const [year, month, day] = inputDate.split('-');

    // Create a new Date object using the components of the input date
    const dateObject = new Date(year, month - 1, day);

    // Extract the day, month, and year from the date object
    const formattedDay = String(dateObject.getDate()).padStart(2, '0');
    const formattedMonth = String(dateObject.getMonth() + 1).padStart(2, '0');
    const formattedYear = dateObject.getFullYear();

    // Combine the formatted day, month, and year in the desired format
    const formattedDate = `${formattedDay}-${formattedMonth}-${formattedYear}`;

    return formattedDate;
  };

  const [scrollTo, setScrollTo] = useState("");
  const sidebarRef = useRef(null);

  const handleButtonClick = () => {
    // console.log("Scroll position:",sidebarRef.current.viewScrollTop);
    if (sidebarRef.current) {
      setScrollTo(sidebarRef.current.viewScrollTop);
    }
  };

  // useEffect(() => {
  //   setSelectedApp(globalMenu[0]);
  // }, []);

  useEffect(() => {
    if (bankerEmail) {
      setLoading(true);

      if (selectedApp) {
        if (selectedApp?.appName === "Authors") {
          axios
            .get(
              `https://publications.apimachine.com/application/publisher/detail/${bankerEmail}`
            )
            .then(({ data }) => {
              if(data.success){
              setAllPublications(data?.data);
              setSelectedPublication(
                data?.data[0]?.PublicationDetails[0]?.PublicationDetail[0]
              );
              localStorage.setItem("selectedPublication", JSON.stringify(data?.data[0]?.PublicationDetails[0]?.PublicationDetail[0]));
              }
              setLoading(false);
            });
        } else {
          axios
            .get(
              `https://publications.apimachine.com/publication/email/${bankerEmail}`
            )
            .then(({ data }) => {
              setAllPublications(data.data);
              setSelectedPublication(data.data[0]);
              localStorage.setItem("selectedPublication", JSON.stringify(data.data[0]));
              setLoading(false);
            });
        }
      }
    }
  }, [bankerEmail, refetchData, selectedApp]);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `https://publications.apimachine.com/application/publisher/detail/${bankerEmail}`
  //     )
  //     .then(({ data }) => {});
  // }, []);

  useEffect(() => {
    if (bankerEmail) {
      axios
        .get(
          `https://publications.apimachine.com/application/publisher/detail/${bankerEmail}`
          // `https://publications.apimachine.com/publisher?email=${bankerEmail}`
        )
        .then(({ data }) => {
          if (data.status) {
            setAuthorDetail(data.data[0]);
            localStorage.setItem("AuthorData", JSON.stringify(data.data));
          } else {
            setAuthorDetail(null);
            localStorage.setItem("AuthorData", null);
          }
        });
    }
  }, [bankerEmail]);

  useEffect(() => {
    if (localStorage.getItem("selectedApp") && selectedApp === null) {
      setSelectedApp(JSON.parse(localStorage.getItem("selectedApp")));
    } else if (localStorage.getItem("selectedApp")) {
      localStorage.setItem("selectedApp", JSON.stringify(selectedApp));
    } else {
      localStorage.setItem("selectedApp", JSON.stringify(globalMenu[0]));
      setSelectedApp(globalMenu[0]);
    }
  }, [selectedApp]);

  useEffect(() => {
    if (localStorage.getItem("loginData")) {
      setLoginData(JSON.parse(localStorage.getItem("loginData")));
    }
  }, [localStorage.getItem("loginData")]);

  useEffect(() => {
    if (localStorage.getItem("bankerEmailNew")) {
      setBankerEmail(localStorage.getItem("bankerEmailNew"));
    } else {
      setBankerEmail(loginData?.user?.email);
    }
    setSelectedApp(JSON.parse(localStorage.getItem("selectedApp")));
  }, []);

  useEffect(() => {
    axios
      .get(`https://comms.globalxchange.io/coin/vault/get/all/coins`)
      .then((res) => {
        if (res.data.status) {
          setAllCoins(res.data.coins);
          setSelectedCoin({
            coinImage:
              "https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png",
            coinName: "US Dollar",
            coinSymbol: "USD",
            market_cap: 0,
            symbol: "$",
            type: "fiat",
            usd_price: 1,
            volume_24hr: 0,
            _24hrchange: 0,
            _id: "5f21042d0562332558c93180",
          });
        }
      });
  }, []);

  useEffect(() => {
    setAppLoading(true);
    axios
      .get("https://comms.globalxchange.io/gxb/apps/get")
      .then((res) => {
        const { data } = res;
        if (data.status) {
          const { apps } = data;
          setAppList(apps);
        }
      })
      .finally(() => setAppLoading(false));
  }, []);

  useEffect(() => {
    setMcbAdminLoading(true);
    if (bankerEmail) {
      axios
        .get(
          `https://comms.globalxchange.io/gxb/app/gxlive/user/operator/get?email=${bankerEmail}&show_apps=true`
        )
        .then((res) => {
          if (res.data.operators.length > 0) {
            setAllBrands(res.data.operators);
            setMcbAdminLoading(false);

            if (localStorage.getItem("selectedBrand")) {
              const found = res.data.operators.find(
                (o) =>
                  o._id ===
                  JSON.parse(localStorage.getItem("selectedBrand"))._id
              );
              console.log(
                found,
                JSON.parse(localStorage.getItem("selectedBrand"))._id,
                "kqbwdkjwebd"
              );
              if (found !== null && found !== undefined) {
                setSelectedBrand(
                  JSON.parse(localStorage.getItem("selectedBrand"))
                );
              } else {
                setSelectedBrand(res.data.operators[0]);
                // localStorage.setItem(
                //   "selectedBrand",
                //   JSON.stringify(res.data.operators[0])
                // );
              }
            } else {
              setSelectedBrand(res.data.operators[0]);
              // localStorage.setItem(
              //   "selectedBrand",
              //   JSON.stringify(res.data.operators[0])
              // );
            }
          }
        });
    }
  }, [bankerEmail]);

  useEffect(() => {
    setCoinLoading(true);
    axios
      .post("https://comms.globalxchange.io/coin/vault/service/coins/get", {
        app_code: "ice",
      })
      .then((res) => {
        const { data } = res;
        if (data.status) {
          const { coins_data } = data;
          setCoinList(coins_data);
        }
      })
      .finally(() => setCoinLoading(false));
  }, []);

  useEffect(() => {
    console.log("changeapp", selectedBrand);
    setMcbAdminLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/gxb/apps/get?operator_id=${selectedBrand?.operator_id}`
      )
      .then((res1) => {
        setAllAppsForBrand(res1.data.apps);
        setMcbAdminLoading(false);
        if (localStorage.getItem("selectedBrandApp")) {
          const found = res1.data.apps.find(
            (o) =>
              o._id === JSON.parse(localStorage.getItem("selectedBrandApp"))._id
          );
          if (found !== null && found !== undefined) {
            setSelectedBrandApp(
              JSON.parse(localStorage.getItem("selectedBrandApp"))
            );
          } else {
            setSelectedBrandApp(res1.data.apps[0]);
            // localStorage.setItem(
            //   "selectedBrandApp",
            //   JSON.stringify(res1.data.operators[0])
            // );
          }
        } else {
          setSelectedBrandApp(res1.data.apps[0]);
          // localStorage.setItem(
          //   "selectedBrandApp",
          //   JSON.stringify(res1.data.operators[0])
          // );
        }
      });
  }, [selectedBrand]);

  useEffect(() => {
    if (selectedBrand && allAppsForBrand.length > 0) {
      localStorage.setItem("selectedBrand", JSON.stringify(selectedBrand));
    }
  }, [selectedBrand, allAppsForBrand]);

  useEffect(() => {
    if (selectedBrandApp && allBrands.length > 0) {
      localStorage.setItem(
        "selectedBrandApp",
        JSON.stringify(selectedBrandApp)
      );
    }
  }, [selectedBrandApp, allBrands]);

  // useEffect(() => {
  //   console.log("call1")
  //     axios
  //       .get(
  //         `https://comms.globalxchange.io/coin/investment/path/get?investmentType=EQT&banker_email=${localStorage.getItem(
  //           "bankerEmailNew"
  //         )}`
  //       )
  //       .then((res) => {
  //         if (res.data.status) {
  //           // let coinsymbol
  //           if (!selectedShareToken) {
  //             // coinsymbol = selectedShareToken?.token_profile_data.coinSymbol
  //             // coinsymbol = res.data.paths[0].token_profile_data.coinSymbol
  //             localStorage.setItem("selectedShareCoin", res.data.paths[0].token_profile_data.coinSymbol)
  //           }
  //         } else {
  //           localStorage.setItem("selectedShareCoin", "Nothingg")
  //         }
  //       })
  // }, [selectedShareToken])

  const handleReplaceTab = (item) => {
    // if (licenseCheck === "active") {
    if (
      tabs.findIndex((o) => o.menuName === item.menuName) < 0 ||
      tabs.length < 1
    ) {
      const index = tabs.findIndex(
        (o) => o.menuName === selectedTab.menuName
      );
      console.log(
        tabs.findIndex((o) => o.menuName === selectedTab.menuName),
        selectedTab,
        "jhwgjwhe"
      );
      tabs.splice(index, 1, item);
      setSelectedTab(tabs.length < 1 ? tabs[index] : item);
    } else {
      setSelectedTab(item);
    }
    // }
  };
  console.log("zxczxcxcvxcvxcv", showSubDraw);
  const value = {
    mcbMenu,
    setgetBankerTag,
    getBankerTag,
    tabSelected,
    setTabSelected,
    globalMenu,
    selectedApp,
    setSelectedApp,
    loading,
    setLoading,
    loginData,
    setLoginData,
    collapse,
    setCollapse,
    active,
    setActive,
    modalOpen,
    setModalOpen,
    tabs,
    setTabs,
    selectedTab,
    setSelectedTab,
    globalMenuAdd,
    setGlobalMenuAdd,
    bankerEmail,
    setBankerEmail,

    selectedFilter1,
    setSelectedFilter1,
    selectedFilter2,
    setSelectedFilter2,
    selectedFilter21,
    setSelectedFilter21,
    filter1,
    setFilter1,
    filter2,
    setFilter2,
    customerEmailFilter,
    setCustomerEmailFilter,
    allCoins,
    setAllCoins,
    selectedCoin,
    setSelectedCoin,
    openCoinFilter,
    setOpenCoinFilter,
    refetchApi,
    setRefetchApi,
    query,
    setQuery,
    shareToken,
    setShareToken,
    allShareToken,
    setAllShareToken,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    FormatNumber,
    FormatCurrency,
    filters,
    setFilters,
    globalFilter,
    setGlobalFilter,
    showSubDraw,
    setShowSubDraw,
    bondSelected,
    setBondSelected,

    //Bond Filters
    selectedAssetFilters,
    setSelectedAssetFilters,
    selectedStatusFilters,
    setSelectedStatusFilters,
    selectedLengthFilter,
    setSelectedLengthFilter,
    refetchBonds,
    setRefetchBonds,

    //Terminals
    selectedTerminal,
    setSelectedTerminal,
    refetchTiers,
    setRefetchTiers,
    showMobileMenu,
    setShowMobileMenu,

    //Capitalized
    selectedShareToken,
    setSelectedShareToken,
    selectedAppData,
    setSelectedAppData,
    holderData,
    setHolderData,
    theApp,
    setTheApp,
    compData,
    setCompData,
    DistribData,
    setDistribData,
    DistributionData,
    setDistributionData,
    compLoader,
    setCompLoader,
    distLoader,
    setDistLoader,
    selectedSubs,
    setSelectedSubs,
    licenseCheck,
    setLicenseCheck,
    contractsData,
    setContractsData,
    coinsCombinations,
    setCoinsCombinations,
    searchTerm,
    setSearchTerm,
    //menus
    bankerMenu,

    //Functions

    handleReplaceTab,

    //MCB States

    globalMenu,
    mcbMenu,
    collapse,
    setCollapse,
    selectedApp,
    setSelectedApp,
    tabs,
    setTabs,
    selectedTab,
    setSelectedTab,
    loginData,
    setLoginData,
    bankerTag,
    setBankerTag,
    login,
    setLogin,
    Lock,
    Collapse_img,
    Collapse1_img,
    defaultImg,
    allBankers,
    setAllBankers,
    bankerEmail,
    setBankerEmail,
    allCoins,
    setAllCoins,
    selectedCoin,
    setSelectedCoin,
    selectedFilter1,
    setSelectedFilter1,
    selectedFilter2,
    setSelectedFilter2,
    selectedFilter21,
    setSelectedFilter21,
    filter1,
    setFilter1,
    filter2,
    setFilter2,
    customerEmailFilter,
    setCustomerEmailFilter,
    openCoinFilter,
    setOpenCoinFilter,
    globalMenuAdd,
    setGlobalMenuAdd,
    FormatNumber,
    selectedCoinSplash,
    setSelectedCoinSplash,
    showDraw,
    setShowDraw,
    selectedMenu,
    setSelectedMenu,
    selectedSplashCoin,
    setSelectedSplashCoin,
    selectedTemplate,
    setSelectedTemplate,
    templateList,
    selectedTemplateMenu,
    setSelectedTemplateMenu,
    selectedBrand,
    setSelectedBrand,
    selectedBrandApp,
    setSelectedBrandApp,
    mcbAdminLoading,
    setMcbAdminLoading,
    allBrands,
    setAllBrands,
    allApps,
    setAllApps,
    selectedMcbDashboardApp,
    setSelectedMcbDashboardApp,

    showSubDraw,
    setShowSubDraw,
    refetchAppData,
    setRefetchAppData,
    globalFilter,
    setGlobalFilter,
    selectedAssetFilters,
    setSelectedAssetFilters,
    selectedStatusFilters,
    setSelectedStatusFilters,
    selectedLengthFilter,
    setSelectedLengthFilter,
    allAppsForBrand,
    setAllAppsForBrand,
    selectedMcbDashboardBrand,
    setSelectedMcbDashboardBrand,
    refetchBrandData,
    setRefetchBrandData,

    selectedMcbAssetsCrypto,
    setSelectedMcbAssetsCrypto,
    selectedMcbAssetsForex,
    setSelectedMcbAssetsForex,
    showMobileMenu,
    setShowMobileMenu,

    refetchFieldGroupData,
    setRefetchFieldGroupData,
    selectedFieldGroup,
    setSelectedFieldGroup,
    isMobile,
    setIsMobile,
    theCurrency,
    setTheCurrency,
    refreshCall,
    setRefreshCall,
    coinIIRD,
    setCoinIIRD,
    tabSelected,
    setTabSelected,
    theAsset,
    setTheAsset,
    crmUser,
    setCrmUser,
    crmData,
    setCrmData,
    selectedSubs,
    coinList,
    appList: appListFinal,
    appLoading,
    setSelectedSubs,
    licenseCheck,
    coinLoading,
    setLicenseCheck,
    globalSearch,
    setGlobalSearch,
    userType,
    setUserType,
    coinListObject,
    coinSelect,
    setCoinSelect,
    searchQuery,
    setSearchQuery,
    getDisplayDate,
    getDisplayTime,
    web3Menu,
    authorsMenu,
    ClassMenu,
    appsMenu,
    allPublications, setAllPublications,
    selectedPublication, setSelectedPublication,
    slider,
    setSlider,
    MainMenu,
    step,
    setStep,
    mainMenu,
    setMainMenu,
    getDays,
    refetchCourses,
    setRefetchCourses,
    selectedCourse,
    setSelectedCourse,
    showCourseSubDraw,
    setShowCourseSubDraw,
    selectedFilterRequest,
    setSelectedFilterRequest,
    formatDate,
    scrollTo,
    setScrollTo,
    cardOpen,
    setCardOpen,
    mallsMenu,
    sidebarRef,
    retailerTab,
    setRetailerTab,
    product,
    setProduct,
    buy,
    setBuy,
    mallCoindata,
    setMallCoinData,
    filteredcoins,
    setfilteredcoins,
    popularAction,
    setPopularAction,
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    selectedDropDown,
    setSelectedDropDown,
    fetchProductData, setFetchProductData,
    productSubDrawer, setProductSubDrawer,
    refetchProduct, setRefechProduct
  };
  return (
    <GlobalContex.Provider value={value}>{children}</GlobalContex.Provider>
  );
};
